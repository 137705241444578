<template>
    <el-row>
        <el-col class="register">
            <el-col class="register-condition">
                <el-col class="register-content">
                    <el-col class="title">伊登云商城</el-col>
                    <el-col class="title en">EDEN CLOUD SHOPPING MALL</el-col>
                    <el-col class="img">
                        <img src="@/img/user/login_bg_1.png">
                    </el-col>
                </el-col>
                <el-col class="register-container">
                    <el-col class="register-title">欢迎注册</el-col>
                    <el-col class="register-title en">WELCOME TO REGISTER</el-col>
                    <el-col class="line"></el-col>
                    <el-col class="form">
                        <el-form ref="registerForm" :model="registerForm" :rules="registerValidate">
                            <el-form-item prop="email">
                                <el-input placeholder="请输入您的邮箱" class="register-input"
                                          v-model="registerForm.email"></el-input>
                            </el-form-item>

                            <el-form-item prop="verifyCode">
                                <el-input placeholder="请输入邮箱验证码" class="register-input verify-code"
                                          v-model="registerForm.verifyCode"/>
                                <el-button :disabled="registerForm.emailSingle" @click="sendEmailCode('registerForm')">
                                    {{btnMsg}}
                                </el-button>
                            </el-form-item>
                            <el-form-item prop="companyName">
                                <el-input @keyup.native="clearBlank" placeholder="请输入公司名" class="register-input" v-model="registerForm.companyName"/>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input @keyup.native="clearBlank" placeholder="请设置您的登录密码" class="register-input" type="password"
                                          v-model="registerForm.password"/>
                            </el-form-item>
                            <el-form-item prop="duplicatePassword">
                                <el-input @keyup.native="clearBlank" placeholder="请再次输入您的登录密码" class="register-input" type="password"
                                          v-model="registerForm.duplicatePassword"/>
                            </el-form-item>
                            <el-form-item prop="single">
                                <el-checkbox v-model="registerForm.single">
                                    我已阅读并同意
                                </el-checkbox>
                                <div style="display: inline;margin-left: 8px;">
                                    <a href="/#/statement/userAgreement" target="_blank" style="text-decoration: none">
                                        《用户许可协议》
                                    </a>
                                    <span style="color: #0c0c0c">和</span>
                                    <a href='/#/statement/privacyStatement' target="_blank" style="text-decoration: none">
                                        《隐私政策协议》
                                    </a>
                                </div>
                            </el-form-item>

                        </el-form>
                    </el-col>
                    <el-col class="button">
                        <el-button @click="register('registerForm')" :loading="submitLoading" :disabled="!registerForm.single">
                            <i>
                                <img src="@/img/user/icon/icon_btn.svg">
                            </i>
                        </el-button>
                    </el-col>
                </el-col>
            </el-col>
        </el-col>
    </el-row>
</template>
<script>
    // import CryptoJS from 'crypto-js'
    import user from "../../api/user"
    import md5JS from "../../utils/md5"
    import CryptoJS from "crypto-js";

    export default {
        name: "registry",
        data() {
            // 检验输入密码类型
            const passwordValidation = (rule, value, callback) => {
                var reg = /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{6,20}$/;
                if (!reg.test(value)) {
                    callback(new Error("至少包含英文大小写、数字、特殊字符其中3种"));
                }
                callback();
            };
            // 检验再次输入的密码是否一致
            const successPassword = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.registerForm.password) {
                    callback(new Error('两次输入密码不一致'))
                }
                callback();
            };
            return {
                btnMsg: '获取验证码',
                emailCountNum: 60,//倒计时周期
                submitLoading: false,
                registerForm: {
                    email: '',
                    emailSingle: false, // 获取验证码按钮禁用状态
                    verifyCode: '',
                    companyName: '',
                    password: '',
                    duplicatePassword:'',
                    single: false, // 是否勾选同意
                },
                registerValidate: {
                    email: [
                        {required: true, message: '邮箱不能为空'},
                        {type: 'email', message: '邮件格式不正确', trigger: ['blur','change']}
                    ],
                    verifyCode: [
                        {required: true, message: '验证码不能为空'}
                    ],
                    companyName: [
                        {required: true, message: '公司名称不能为空'}
                    ],
                    password: [
                        {required: true, message: '密码不能为空', trigger: 'blur'},
                        {required: true, min: 6, max: 20, message: '密码长度为6-20', trigger: 'blur'},
                        {validator: passwordValidation, trigger: 'blur'}
                    ],
                    duplicatePassword: [
                        {required: true, message: '请输入确认密码', trigger: 'blur'},
                        {validator: successPassword, min: 6, trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {
        },
        created() {
            this.getCookie()
        },
        methods: {
            register(form){
              this.$refs[form].validate((valid) => {
                    if (valid) {
                        if (this.submitLoading === false) {
                            this.submitLoading = true;
                            this.$forceUpdate();
                        }
                        let obj = this;
                        user.register({
                                'email': obj.registerForm.email,
                                'password': md5JS.hex_md5(obj.registerForm.password.trim()),
                                'mailCode': obj.registerForm.verifyCode,
                                'type': 2,//type为2是公司
                                'companyName': obj.registerForm.companyName
                            }).then((res) => {
                                if (res.code == 200) {
                                    obj.submitLoading = false;
                                    obj.$refs.registerForm.resetFields();
                                    obj.$message.success(res.message);
                                    obj.$forceUpdate();
                                    setTimeout(() => {
                                        parent.document.location.href = '/#/homeIndex';
                                    },1000);
                                } else {
                                    obj.$message.error(res.message);
                                }
                        })
                        obj.submitLoading = false;
                    }
                })
            },
            // 发送验证码
            sendEmailCode(name) {
                this.$refs[name].validateField('email', (error) => {
                    let obj = this;
                    // 邮箱格式正确
                    if (error == "") {
                        this.countDown();
                        user.sendVerificationCode({email:obj.registerForm.email}).then((res) => {
                            if (res.code == 200){
                                obj.$message.success(res.message);
                                console.log(res.message)
                            } else {
                                obj.$message.error(res.message);
                                console.log(res.message);
                            }
                        })

                    } else {
                        obj.registerValidate.email.message = error
                    }

                })
            },
            // 验证码倒计时
            countDown() {
                // 更改btn状态
                let obj = this;
                // obj.emailCountFlag = !obj.emailCountFlag;
                // 设置倒计时
                obj.intervalBtn = setInterval(() => {
                    if (obj.emailCountNum <= 0) {
                        // 重置btn提示信息
                        obj.btnMsg = "获取验证码";
                        // 清除定时器
                        clearInterval(obj.intervalBtn)
                        // 更改btn状态
                        // obj.emailCountFlag = !obj.emailCountFlag;
                        obj.registerForm.emailSingle = false;
                        // 重置倒计时状态
                        obj.emailCountNum = 60;
                    } else {
                        // 倒计时
                        obj.registerForm.emailSingle = true;
                        obj.emailCountNum--;
                        obj.btnMsg = obj.emailCountNum + "秒后重新获取"
                    }
                }, 1000);
            },
            clearBlank() {
                let obj = this;
                console.log(obj.registerForm.email);
                //空格回退
                obj.registerForm.eamil = obj.registerForm.email.replace(/[, ]/g, '');
                obj.registerForm.companyName = obj.registerForm.companyName.replace(/[, ]/g, '');
                obj.registerForm.password = obj.registerForm.password.replace(/[, ]/g, '');
                obj.registerForm.duplicatePassword = obj.registerForm.duplictePassword.replace(/[, ]/g, '');
            },
        }
    }
</script>

<style lang="less" scoped>

    .register {
        background: url("../../img/user/login_bg.png") no-repeat top center;
        height: 100vh;
        padding-top: 100px;
        .register-condition {
            width: 1200px;
            float: unset;
            margin: auto;
        }

        .register-content {
            padding-top: 87px;
            width: 510px;

            .title {
                font-size: 40px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 40px;
            }

            .en {
                font-size: 26px;
                width: auto;
                margin-top: 16px;
            }

            .img {
                margin-top: 26px;
                width: auto;
            }
        }

        .register-container {
            width: 500px;
            height: 590px;
            background: #FFFFFF;
            box-shadow: 0px 0px 23px 0px rgb(126, 126, 126, 85%);
            border-radius: 15px;
            padding: 55px 51px;
            float: right;

            .register-title {
                font-size: 24px;
                font-weight: bold;
                color: #252525;
            }

            .en {
                color: rgba(37, 37, 37, 0.3);
                margin: 16px 0;
            }

            .line {
                width: 28px;
                height: 5px;
                background: #252525;
                border-radius: 3px;
            }

            .form {
                margin-top: 16px;

                .register-input {
                    /*margin-bottom: 24px;*/

                    /deep/ input {
                        height: 42px;
                        border-radius: 10px;
                        border: 1px solid rgba(37, 37, 37, 0.5000);
                    }
                }

                .verify-code {
                    width: auto;

                    /deep/ input {
                        width: 256px;
                    }

                }

                /deep/ .el-button {
                    width: 126px;
                    height: 42px;
                    background: #252525;
                    border-radius: 10px;
                    margin-left: 16px;

                    font-size: 14px;
                    color: #FFFFFF;
                }

                /deep/ .el-button.is-disabled {
                    padding: 0;
                }
            }

            .button {
                height: 39px;
                //background: #252525;
                //border-radius: 20px;
                /*margin-top: 32px;*/
                margin-top: 20px;
                //padding: 15px 134px;
                text-align: center;

                .el-button {
                    width: 293px;
                    height: 39px;
                    background: #252525;
                    border-radius: 20px;
                }

                /deep/ .el-icon-loading {
                    color: #FFF;
                }
                .icon-btn {
                    background: url("../../img/user/icon/icon_btn.svg") no-repeat top center;
                    width: 24px;
                    height: 10px;
                }

                .icon-btn:before {
                    content: ' ';

                }
            }
        }
    }


</style>
